/* Login/Sign-up page */
.loginPage {
  font-family: "Montserrat";
  color: white;
  font-weight: 300;
  background: #1876d1;
  width: 100%;
  height: 100%;
  text-align: center;
}

.loginText,
.signinText {
  padding-top: 22%;
}

@media (min-width: 750px) and (max-width: 1100px) {
  .loginText,
  .signinText {
    padding-top: 12%;
  }
}

@media (min-width: 1100px) {
  .loginText,
  .signinText {
    padding-top: 7%;
  }
}

body h1 {
  font-size: 40px;
  font-weight: 200;
  padding-bottom: 1%;
  margin-top: 15px;
}

.welcome {
  margin: 0;
  font-size: 20px;
}

.loginInput {
  border: 1px solid rgba(252, 252, 252, 0.4);
  background-color: rgba(252, 252, 252, 0.2);
  width: 250px;
  border-radius: 3px;
  font-family: "Source Sans Pro", sans-serif;
  padding: 10px 15px;
  margin: 0 auto 10px auto;
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  color: white;
}

.loginButton {
  appearance: none;
  outline: 0;
  background-color: white;
  border: 0;
  padding: 10px 15px;
  color: #1876d1;
  border-radius: 3px;
  width: 250px;
  font-size: 18px;
  opacity: 0.8;
}

.loginButton:hover {
  opacity: 1;
}

.loginInput:hover {
  border: 1px solid rgba(252, 252, 252, 0.9);
}

.signupLink,
.loginLink {
  font-size: 14px;
  color: white;
  opacity: 0.7;
}

.signupLink:hover,
.loginLink:hover {
  color: white;
  opacity: 1;
}

.signupLink:visited {
  color: white;
}

/* Navbar */

.customNavbar {
  font-size: 17px;
  font-family: "Montserrat";
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

/* Homepage - Title & image */
#bookServiceButton {
  width: 23%;
  top: 70%; 
  left: 5%;
}

#bookServiceButton:hover {
  color: white;
}

@media (max-width: 600px) {
  #titleText {
    padding-top: 32px;
    position: relative;
    font-size: 5vh;
    text-align: center;
    left: 0;
    background-color: 1876D1;
    color: white;
    margin-bottom: 5%;
    height:40%;
    font-weight: 500;
  }

  #bookServiceButton {
    top: 83% !important;
    background-color: black;
    width: 60%;
    left: 20%;
  }

  #bookServiceButton:hover {
    background-color:white;
    color: black;
  }

  #titleImage {
    width: 100%;
    height: 60%;
    object-fit: cover;
    object-position: 100%;
    margin-bottom: 0 !important;
  }
}

@media (min-width: 601px) and (max-width: 752px) {
  #titleText {
    padding: 3% 0% 5% 0%;
    position: relative;
    font-size: 7vh;
    left: 0;
    background-color: #1876D1;
    text-align: center;
    color: white;
    margin-bottom: 5%;
    font-weight: 500;
    height: 45%;
  }

  #titleImage {
    width: 100%;
    height: 55%;
    object-fit: cover;
    object-position: 90%;
    margin-bottom: 0 !important;
  }

  #bookServiceButton {
    top: 83% !important;
    background-color: black;
    width: 36%;
    left: 32%;
  }

  #bookServiceButton:hover {
    background-color:white;
    color: black;
  }
}

/* Homepage - Cards */
@media (min-width: 753px) and (max-width: 1023px) {
  .serviceCards {
    width: 220px !important;
  }

  #bookServiceButton {
    width: 38%;
  }

}

/* Homepage - Footer */

.footer-icon {
  margin: 20px 10px;
}

#footer {
  background-color: #1876d1;
  position: relative;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 5%;
  color: white;
}

/* 404 */

.error404page {
  padding-top: 7%;
  padding-left: 15%;
  background: #1876d1;
  font-family: "Montserrat";
  color: white;
  height: 100%;
}

#oops {
  font-size: 8vw;
  font-weight: 500;
}

#pageNotFound {
  font-size: 3vw;
  padding-bottom: 1%;
}

#errorCode {
  font-size: 2vw;
  padding-bottom: 1%;
}

@media (max-width: 900px) {
  .error404page {
    padding: 15%;
  }
  #oops {
    font-size: 12vw;
  }
  #pageNotFound {
    font-size: 6vw;
  }
  #errorCode {
    font-size: 4vw;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .error404page {
    padding: 8% 12%;
  }
  #oops {
    font-size: 10vw;
  }
  #pageNotFound {
    font-size: 5vw;
  }
  #errorCode {
    font-size: 3vw;
  }
}

.footer-icon:hover {
  transform: scale(1.5);
}

/* Contact page CSS  */
#contactPage {
  text-align: center;
  margin-top: 13vh;
}

#sendButton {
  margin-top: 10px;
  width: 100%;
  border-radius: 15px;
  padding: 7px;
  font-family: "Montserrat";
  background-color: #1876d1;
}
.contactInput {
  padding: 7px;
  width: 100%;
  font-family: "Montserrat";
  border-radius: 15px;
  border: 1px solid gray;
}
#questionBox {
  width: 100%;
  font-family: "Montserrat";
  height: 100px;
  border-radius: 15px;
  padding: 10px;
  border: 1px solid gray;
}
/* END OFContact page CSS  */

/* Dashboard CSS */
.subtitle {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 7vh;
  color: black;
  text-align: center;
}

.buttonHoverEffect:hover {
  background-color: #1876d0 !important;
}
